import { MdPerson } from "react-icons/md";
import {
  Flex,
  Icon,
  Input,
  Button,
  FormControl,
  FormLabel,
  Stack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { supabase } from "../utils/supabaseClient";

const Header = () => {
  const [username, setUsername] = useState("");
  const [initialUsername, setInitialUsername] = useState(""); // Track initial username
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const fetchUserData = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();

      if (session) {
        setEmail(session.user.email);

        const { data: profile, error: profileError } = await supabase
          .from("profiles")
          .select("*")
          .eq("id", session.user.id)
          .single();

        if (profile) {
          const fetchedUsername = profile.username || "";
          setUsername(fetchedUsername);
          setInitialUsername(fetchedUsername); // Store initial username

          // Automatically open modal if username is empty
          if (!fetchedUsername) {
            console.log("No username found. Opening modal for setup.");
            onOpen(); // Open the modal if no username is found
          }
        } else {
          console.error("Profile fetch error:", profileError);
        }
      }
    };

    fetchUserData();
  }, [onOpen]);

  const handleSaveProfile = async () => {
    setError(null);
    setSuccess(null);

    const {
      data: { session },
    } = await supabase.auth.getSession();

    if (session) {
      const userId = session.user.id;
      const { error: profileError } = await supabase
        .from("profiles")
        .update({
          username: username,
        })
        .eq("id", userId);

      if (profileError) {
        setError(profileError.message);
      } else {
        setSuccess("Profile updated successfully!");
        setInitialUsername(username); // Update initial username
        onClose(); // Close the modal after saving profile
      }
    }
  };

  // Determine if Save Profile button should be enabled
  const isSaveEnabled = username !== initialUsername;

  return (
    <Flex justifyContent="flex-end" w="90vw" alignItems="center" mb="2">
      <Icon
        p="1"
        border="1.5px solid"
        borderColor="gray.500"
        borderRadius="full"
        as={MdPerson}
        w={9}
        h={9}
        color="gray.500"
        cursor="pointer"
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="90%" mx="auto" p={4}>
          <ModalHeader>Set Up Your Account</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <FormControl id="email" isDisabled>
                <FormLabel>Email (pre-filled)</FormLabel>
                <Input type="email" value={email} isDisabled />
              </FormControl>
              <FormControl id="username">
                <FormLabel>Username</FormLabel>
                <Input
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </FormControl>
              {error && <Text color="red.500">{error}</Text>}
              {success && <Text color="green.500">{success}</Text>}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              onClick={handleSaveProfile}
              disabled={!isSaveEnabled}
            >
              Save Profile
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default Header;
