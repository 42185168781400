import React, { useState } from "react";
import { supabase } from "../utils/supabaseClient";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  Text,
  Heading,
  Stack,
} from "@chakra-ui/react";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  //check if everything is filled out
  const isFormValid = email && password;

  const handleLogin = async () => {
    setError(null);
    console.log("Attempting to sign in...");

    // Sign in with Supabase Auth
    const { error: loginError, data } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (loginError) {
      console.error("Login Error:", loginError); // Log login error
      setError(loginError.message);
      return; // Stop here without redirecting
    }

    console.log("Login successful, user data:", data); // Log user data
    const userId = data.user.id;

    // Check if profile exists
    console.log("Checking if profile exists for user ID:", userId);
    const { data: profile, error: profileError } = await supabase
      .from("profiles")
      .select("*")
      .eq("id", userId)
      .single();

    if (profileError) {
      console.error("Profile fetch error:", profileError.message); // Log profile fetch error
    } else {
      console.log("Profile found:", profile); // Log profile data if found
    }

    // Redirect based on whether profile exists and if device_id is set
    if (profile) {
      if (profile.device_id) {
        console.log("Device ID exists, redirecting to dashboard...");
        navigate("/dashboard"); // Redirect to dashboard if device_id is set
      } else {
        console.log("Device ID missing, redirecting to PairDevice...");
        navigate("/pairDevice"); // Redirect to PairDevice if device_id is missing
      }
    }
  };

  return (
    <Box maxW="md" mx="auto" p="6" mt="8" boxShadow="lg" borderRadius="md">
      <Heading as="h2" size="lg" mb="6" textAlign="center">
        Login
      </Heading>
      <Stack spacing={4}>
        <FormControl id="email">
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>
        <FormControl id="password">
          <FormLabel>Password</FormLabel>
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormControl>
        <Button
          disabled={!isFormValid}
          colorScheme="blue"
          onClick={handleLogin}
          width="full"
        >
          Login
        </Button>
        {error && (
          <Text color="red.500" textAlign="center">
            {error}
          </Text>
        )}
        <Text color="blue.500" textAlign="center">
          or
        </Text>
        <Button
          onClick={() => navigate("/register")}
          colorScheme="blue"
          width="full"
          variant="outline"
        >
          Register
        </Button>
      </Stack>
    </Box>
  );
};

export default Login;
