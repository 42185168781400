import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  Text,
  Heading,
  Stack,
} from "@chakra-ui/react";
import { supabase } from "../utils/supabaseClient";
import { useNavigate } from "react-router-dom";
import Avatar from "./Avatar";

const AccountSetup = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState(""); // To display the pre-filled email
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  // Fetch the email from the session on page load
  useEffect(() => {
    const fetchUserData = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();

      if (session) {
        console.log("Session data:", session); // Debug log for session data
        setEmail(session.user.email); // Pre-fill email field with session email

        // Fetch the existing profile information
        const { data: profile, error: profileError } = await supabase
          .from("profiles")
          .select("*")
          .eq("id", session.user.id)
          .single();

        if (profileError) {
          console.error("Profile fetch error:", profileError.message); // Debug log for profile fetch error
        }

        if (profile) {
          console.log("Profile data:", profile); // Debug log for profile data
          setUsername(profile.username || ""); // Populate the username if it exists
        }
      } else {
        console.error("No session found."); // Debug log if session is not found
      }
    };

    fetchUserData();
  }, []);

  // Update the existing profile
  const handleSaveProfile = async () => {
    setError(null);
    setSuccess(null);

    console.log("Saving profile..."); // Debug log for profile save start

    const {
      data: { session },
    } = await supabase.auth.getSession();

    if (session) {
      const userId = session.user.id;

      console.log("User ID:", userId); // Debug log for user ID

      // Update the existing profile with the new username
      const { error: profileError, data } = await supabase
        .from("profiles")
        .update({
          username: username, // Add or update username
        })
        .eq("id", userId);

      if (profileError) {
        console.error("Profile update error:", profileError.message); // Debug log for profile update error
        setError(profileError.message);
      } else {
        console.log("Profile updated successfully:", data); // Log updated data
        setSuccess("Profile updated successfully!");
        navigate("/pairDevice"); // Redirect to dashboard after saving profile
      }
    } else {
      console.error("No session found for profile update."); // Debug log for missing session
      setError("You are not logged in.");
    }
  };

  return (
    <Box maxW="md" mx="auto" p="6" mt="8" boxShadow="lg" borderRadius="md">
      <Heading as="h2" size="lg" mb="6" textAlign="center">
        Set Up Your Profile
      </Heading>
      <Stack spacing={4}>
        <Avatar />
        <FormControl id="email" isDisabled>
          <FormLabel>Email (pre-filled)</FormLabel>
          <Input type="email" value={email} isDisabled />
        </FormControl>
        <FormControl id="username">
          <FormLabel>Username</FormLabel>
          <Input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </FormControl>
        <Button colorScheme="blue" onClick={handleSaveProfile} width="full">
          Save Profile
        </Button>
        {error && <Text color="red.500">{error}</Text>}
        {success && <Text color="green.500">{success}</Text>}
      </Stack>
    </Box>
  );
};

export default AccountSetup;
