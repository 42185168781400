import {
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  Card,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { supabase } from "../utils/supabaseClient";
import LoadingAnimation from "./LoadingAnimation";

const DataCards = () => {
  const [phData, setPhData] = useState([]);
  const [chData, setChData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // Helper function to determine increase/decrease text and arrow
  const getComparisonInfo = (latestValue, previousValue) => {
    if (previousValue === null || latestValue === "N/A") return null; // Skip if no previous data

    const percentageChange =
      ((latestValue - previousValue) / previousValue) * 100;

    // Check if the change is above the threshold
    if (Math.abs(percentageChange) >= THRESHOLD) {
      const isIncrease = latestValue > previousValue;

      return {
        isIncrease,
        percentageChange: Math.abs(percentageChange).toFixed(2), // Return percentage change with 2 decimal points
      };
    }

    // If change is below the threshold, return null (no arrow or text will be shown)
    return null;
  };

  // Define a constant for the threshold
  const THRESHOLD = 0.1; // 0.10% threshold for changes

  // Get the latest and previous fetched values for PH, CH, and temperature
  const latestPhValue =
    phData.length > 0 ? phData[phData.length - 1].value : "N/A";
  const previousPhValue =
    phData.length > 1 ? phData[phData.length - 2].value : null;
  const latestChValue =
    chData.length > 0 ? chData[chData.length - 1].value : "N/A";
  const previousChValue =
    chData.length > 1 ? chData[chData.length - 2].value : null;
  const latestTempValue =
    tempData.length > 0 ? tempData[tempData.length - 1].value : "N/A";
  const previousTempValue =
    tempData.length > 1 ? tempData[tempData.length - 2].value : null;

  // Get comparison info for temperature, pH, and chlorine
  const tempComparison = getComparisonInfo(latestTempValue, previousTempValue);
  const phComparison = getComparisonInfo(latestPhValue, previousPhValue);
  const chComparison = getComparisonInfo(latestChValue, previousChValue);

  // Function to fetch the latest sensor data (ph, ch, temperature)
  const fetchStatsData = async () => {
    try {
      const {
        data: { session },
        error: sessionError,
      } = await supabase.auth.getSession();

      if (sessionError || !session) {
        setError("Error retrieving session data.");
        console.error("Session error:", sessionError);
        setLoading(false);
        return;
      }

      const { data: profile, error: profileError } = await supabase
        .from("profiles")
        .select("id")
        .eq("id", session.user.id)
        .single();

      if (profileError || !profile) {
        setError("Error fetching profile data.");
        console.error("Profile error:", profileError);
        setLoading(false);
        return;
      }

      const profileId = profile.id;

      const [phResponse, chResponse, tempResponse] = await Promise.all([
        supabase
          .from("ph_data")
          .select("value, timestamp")
          .eq("user_id", profileId),
        supabase
          .from("ch_data")
          .select("value, timestamp")
          .eq("user_id", profileId),
        supabase
          .from("temperature_data")
          .select("value, timestamp")
          .eq("user_id", profileId),
      ]);

      setTempData(tempResponse.data || []);
      setPhData(phResponse.data || []);
      setChData(chResponse.data || []);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("An unexpected error occurred.");
    } finally {
      setLoading(false); // Ensure loading is false even if there's an error
    }
  };

  useEffect(() => {
    // Fetch stats data initially
    fetchStatsData();

    // Set interval to fetch data every 10 seconds
    const intervalId = setInterval(() => {
      fetchStatsData();
    }, 10000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  if (loading) {
    return <LoadingAnimation />;
  }

  if (error) {
    return (
      <Flex justifyContent="center" alignItems="center" h="full">
        <Text color="red.500">{error}</Text>
      </Flex>
    );
  }

  return (
    <Flex flexDir="row" gap="3" w="full" h="14vh">
      <Card boxShadow="md" p="4" borderRadius="md" w="full">
        <Stat>
          <StatLabel>Temp.</StatLabel>
          <StatNumber>{latestTempValue}°C</StatNumber>
          {tempComparison && (
            <StatHelpText>
              <StatArrow
                type={tempComparison.isIncrease ? "increase" : "decrease"}
              />
              {tempComparison.percentageChange}%
            </StatHelpText>
          )}
        </Stat>
      </Card>
      <Card boxShadow="md" p="4" borderRadius="md" w="full">
        <Stat>
          <StatLabel>Ch</StatLabel>
          <StatNumber display="flex" gap="1" alignItems="center">
            {latestChValue}
            <Text fontSize="md" color="white">
              ppm
            </Text>
          </StatNumber>
          {chComparison && (
            <StatHelpText>
              <StatArrow
                type={chComparison.isIncrease ? "increase" : "decrease"}
              />
              {chComparison.percentageChange}%
            </StatHelpText>
          )}
        </Stat>
      </Card>
      <Card boxShadow="md" p="4" borderRadius="md" w="full">
        <Stat>
          <StatLabel>Ph</StatLabel>
          <StatNumber>{latestPhValue}</StatNumber>
          {phComparison && (
            <StatHelpText>
              <StatArrow
                type={phComparison.isIncrease ? "increase" : "decrease"}
              />
              {phComparison.percentageChange}%
            </StatHelpText>
          )}
        </Stat>
      </Card>
    </Flex>
  );
};

export default DataCards;
