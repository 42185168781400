import React from "react";
import { Flex } from "@chakra-ui/react";
import { Routes, Route } from "react-router-dom"; // Import only Routes and Route
import Dashboard from "./components/Dashboard";
import Register from "./components/Register";
import Login from "./components/Login";
import AccountSetup from "./components/AccountSetup";
import PairDevice from "./components/PairDevice";

function App() {
  return (
    <Flex
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      w="100vw"
      px="5"
      py="5"
      h="100vh"
    >
      <Routes>
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/pairDevice" element={<PairDevice />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/account-setup" element={<AccountSetup />} />
        <Route path="/" element={<Login />} />
      </Routes>
    </Flex>
  );
}

export default App;
