import React, { useState } from "react";
import { supabase } from "../utils/supabaseClient";
import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  Text,
  Flex,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const PairDevice = () => {
  const [deviceId, setDeviceId] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handlePairDevice = async () => {
    setError(null);
    setStatusMessage("");

    try {
      console.log("Fetching session...");
      // Fetch the current session and user
      const {
        data: { session },
        error: sessionError,
      } = await supabase.auth.getSession();

      if (sessionError || !session) {
        console.error("Session Error:", sessionError);
        setError("You need to log in to pair a device.");
        return;
      }

      const userId = session.user.id;
      console.log("Logged in user ID:", userId);

      // Check if the device exists in the 'devices' table
      console.log("Checking if the device exists with device ID:", deviceId);
      const { data: deviceData, error: deviceError } = await supabase
        .from("devices")
        .select("*")
        .eq("device_id", deviceId)
        .single(); // Ensure we are getting a single result

      if (deviceError) {
        console.error("Device lookup error:", deviceError.message);
        setError("Device not found or invalid.");
        return;
      }

      console.log("Device found:", deviceData);

      // Update the user's profile with the device_id
      console.log("Updating profile with device ID...");
      const { error: profileUpdateError } = await supabase
        .from("profiles")
        .update({ device_id: deviceId })
        .eq("id", userId);

      if (profileUpdateError) {
        console.error("Profile update error:", profileUpdateError.message);
        setError("Failed to update profile with device ID.");
      } else {
        console.log("Profile successfully updated with device ID:", deviceId);
        setStatusMessage("Device successfully paired with your profile!");
        navigate("/Dashboard"); // Redirect to dashboard after saving profile
      }
    } catch (err) {
      console.error("Unexpected error:", err);
      setError("An unexpected error occurred.");
    }
  };

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDir="column"
      h="100vh"
    >
      <Box w="400px" p="6" boxShadow="lg" borderRadius="md">
        <FormControl id="device_id" mb="4">
          <FormLabel>Device ID</FormLabel>
          <Input
            type="text"
            value={deviceId}
            onChange={(e) => setDeviceId(e.target.value)}
            placeholder="Enter your device ID"
          />
        </FormControl>
        <Button
          colorScheme="blue"
          onClick={handlePairDevice}
          width="full"
          mt="4"
        >
          Pair Device
        </Button>
        {error && (
          <Text color="red.500" mt="2">
            {error}
          </Text>
        )}
        {statusMessage && (
          <Text color="green.500" mt="2">
            {statusMessage}
          </Text>
        )}
      </Box>
    </Flex>
  );
};

export default PairDevice;
