import { useEffect, useState } from "react";
import { supabase } from "../utils/supabaseClient";
import { Flex, Input, Image, Box } from "@chakra-ui/react";

export default function Avatar({ url, onUpload }) {
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (url) downloadImage(url);
  }, [url]);

  async function downloadImage(path) {
    try {
      const { data, error } = await supabase.storage
        .from("avatars")
        .download(path);
      if (error) throw error;
      const url = URL.createObjectURL(data);
      setAvatarUrl(url);
    } catch (error) {
      console.log("Error downloading image: ", error.message);
    }
  }

  async function uploadAvatar(event) {
    try {
      setUploading(true);

      if (!event.target.files || event.target.files.length === 0) {
        throw new Error("You must select an image to upload.");
      }

      const file = event.target.files[0];
      const fileExt = file.name.split(".").pop();
      const fileName = `${Math.random()}.${fileExt}`;
      const filePath = `${fileName}`;

      const { error: uploadError } = await supabase.storage
        .from("avatars")
        .upload(filePath, file);

      if (uploadError) throw uploadError;

      onUpload(event, filePath);
    } catch (error) {
      alert(error.message);
    } finally {
      setUploading(false);
    }
  }

  return (
    <Flex
      w="full"
      h="full"
      boxSize="xs"
      align="center"
      justify="center"
      position="relative"
      borderRadius="full"
      bg="gray.200"
      overflow="hidden"
      cursor="pointer"
      onClick={() => document.getElementById("avatar-upload").click()}
    >
      {avatarUrl ? (
        <Image src={avatarUrl} alt="Avatar" boxSize="full" objectFit="cover" />
      ) : (
        <Box boxSize="full" bg="gray.300" />
      )}

      <Input
        id="avatar-upload"
        type="file"
        accept="image/*"
        onChange={uploadAvatar}
        display="none"
      />
    </Flex>
  );
}
