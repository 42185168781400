// theme.js
import { extendTheme } from "@chakra-ui/react";

const config = {
  initialColorMode: "system", // Automatically uses the system preference
  useSystemColorMode: true, // Enable system color mode preference
};

const colors = {
  primary: {
    50: "#e0f7fa", // Lightest blue
    100: "#b3e5fc", // Light blue
    200: "#81d4fa", // Medium blue
    300: "#4fc3f7", // Sky blue
    400: "#29b6f6", // Pool blue (main primary color)
    500: "#039be5", // Deeper blue
    600: "#0288d1", // Darker pool blue
    700: "#0277bd", // Deep blue
    800: "#01579b", // Darkest blue
    900: "#013b6d", // Navy blue
  },
  secondary: {
    50: "#ffebee", // Light pink
    100: "#ffcdd2", // Lighter pink
    200: "#ef9a9a", // Soft red-pink
    300: "#e57373", // Rose pink (to indicate errors or alerts)
    400: "#ef5350", // Alert red (for critical issues like pH imbalance)
    500: "#f44336", // Stronger red
    600: "#e53935", // Deep red
    700: "#d32f2f", // Dark red
    800: "#c62828", // Darker red
    900: "#b71c1c", // Deepest alert red
  },
  success: {
    100: "#d7f7e4", // Light green
    500: "#4caf50", // Green (for normal pool conditions)
    900: "#087f23", // Dark green
  },
  warning: {
    100: "#fff3e0", // Light orange
    500: "#ffa726", // Orange (for warnings or less critical alerts)
    900: "#e65100", // Deep orange
  },
  background: {
    light: "#f0f4f8", // Light gray background
    dark: "#0f1e2e", // Deep blue-black for dark mode
  },
};

const fonts = {
  heading: "'Poppins', sans-serif", // Clean, modern font for headings
  body: "'Inter', sans-serif", // Readable font for body text
};

const components = {
  Button: {
    baseStyle: {
      fontWeight: "bold",
      borderRadius: "md", // Rounded buttons for a modern feel
    },
    variants: {
      solid: {
        bg: "primary.400",
        color: "white",
        _hover: {
          bg: "primary.500",
        },
      },
      outline: {
        borderColor: "primary.400",
        color: "primary.400",
        _hover: {
          bg: "primary.50",
        },
      },
    },
  },
  Text: {
    baseStyle: {
      color: "primary.700",
    },
  },
  Card: {
    baseStyle: {
      bg: "white",
      boxShadow: "lg",
      borderRadius: "lg",
    },
  },
  Switch: {
    baseStyle: {
      track: {
        _checked: {
          bg: "primary.500",
        },
      },
    },
  },
};

const theme = extendTheme({
  config,
  colors,
  fonts,
  components,
  styles: {
    global: {
      body: {
        bg: "background.light",
        color: "primary.700",
      },
      ".chakra-ui-dark": {
        bg: "background.dark",
        color: "white",
      },
    },
  },
});

export default theme;
