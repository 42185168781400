import React, { useState, useEffect } from "react";
import { Flex, Box, Text } from "@chakra-ui/react";
import Header from "./Header";
import DataCards from "./DataCards";
import DeviceData from "./DeviceData";
import { supabase } from "../utils/supabaseClient"; // Import Supabase client

const Dashboard = () => {
  const [username, setUsername] = useState(""); // State for storing username
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsername = async () => {
      try {
        const {
          data: { session },
          error: sessionError,
        } = await supabase.auth.getSession();

        if (sessionError || !session) {
          setError("Error retrieving session data.");
          console.error("Session error:", sessionError);
          return;
        }

        const { data: profile, error: profileError } = await supabase
          .from("profiles")
          .select("username")
          .eq("id", session.user.id)
          .single();

        if (profileError) {
          console.error("Profile fetch error:", profileError.message);
          setError("Error fetching profile data.");
        } else {
          setUsername(profile.username);
        }
      } catch (error) {
        console.error("Unexpected error fetching username:", error);
        setError("An unexpected error occurred.");
      }
    };

    fetchUsername();
  }, []);

  return (
    <Flex
      flexDir="column"
      alignItems="center"
      h="full"
      w="full"
      justifyContent="space-between"
    >
      <Header />
      <Box
        w="full"
        h="full"
        display="flex"
        flexDir="column"
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex flexDir="column" alignItems="center" w="full" h="full">
          {error ? (
            <Text color="red.500">{error}</Text>
          ) : (
            <Text
              fontSize="4xl"
              fontWeight="semibold"
              textAlign="left"
              color="white"
              mb="6"
              w="full"
            >
              Hey, {username}
            </Text>
          )}
          <Flex flexDir="column" gap="3" w="full">
            <DataCards />
            <DeviceData />
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Dashboard;
