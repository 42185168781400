import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import AppWithRouter from "./App"; // Updated import to include the router setup
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom"; // Import BrowserRouter
import { ChakraProvider, Flex, ColorModeScript } from "@chakra-ui/react";
import theme from "./theme";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <Flex w="100vw" h="100vh" justifyContent="center" alignItems="center">
        <BrowserRouter>
          <AppWithRouter />
        </BrowserRouter>
      </Flex>
    </ChakraProvider>
  </React.StrictMode>
);

reportWebVitals();
