import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  Text,
  Heading,
  Stack,
  Checkbox,
} from "@chakra-ui/react";
import { supabase } from "../utils/supabaseClient";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [checked, setChecked] = useState(false);

  //check if everything is filled out
  const isFormValid = email && password && checked;

  const handleRegister = async () => {
    setError(null);
    setSuccess(null);

    // Register the user with Supabase Auth
    const { data, error: authError } = await supabase.auth.signUp({
      email,
      password,
    });

    if (authError) {
      console.error("Auth Error:", authError); // Debug log for authentication error
      setError(authError.message);
      return;
    }

    // If successful, show a message to confirm their email
    setSuccess(
      "Registration successful! Please check your email to confirm your account."
    );
  };

  // This effect will listen for authentication state changes (like email confirmation)
  useEffect(() => {
    const { subscription } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        if (event === "SIGNED_IN") {
          const userId = session.user.id;
          console.log("User signed in, creating profile...");

          try {
            // Insert the profile into the 'profiles' table after email is confirmed
            const { error: profileError } = await supabase
              .from("profiles")
              .insert({
                id: userId,
                email: session.user.email,
              });

            if (profileError) {
              console.error("Profile Insert Error:", profileError); // Debug log for profile insert error
              setError("Error inserting profile: " + profileError.message);
            } else {
              setSuccess("Profile created successfully!");
            }
          } catch (error) {
            console.error("Unexpected Error:", error); // Debug log for unexpected errors
            setError(
              "An unexpected error occurred while creating your profile."
            );
          }
        }
      }
    );

    // Cleanup listener on component unmount
    return () => {
      if (subscription) {
        subscription.unsubscribe(); // Correct subscription unsubscribe
      }
    };
  }, []);

  return (
    <Box maxW="md" mx="auto" p="6" mt="8" boxShadow="lg" borderRadius="md">
      <Heading as="h2" size="lg" mb="6" textAlign="center">
        Register
      </Heading>
      <Stack spacing={4}>
        <FormControl id="email">
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>
        <FormControl id="password">
          <FormLabel>Password</FormLabel>
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormControl>
        <Checkbox
          isChecked={checked}
          onChange={(e) => setChecked(e.target.checked)}
        >
          Accept terms and conditions
        </Checkbox>
        <Button
          disabled={!isFormValid}
          colorScheme="blue"
          onClick={handleRegister}
          width="full"
        >
          Register
        </Button>
        {error && <Text color="red.500">{error}</Text>}
        {success && <Text color="green.500">{success}</Text>}
      </Stack>
    </Box>
  );
};

export default Register;
