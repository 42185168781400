import { Flex, Text, Image } from "@chakra-ui/react";
import { motion } from "framer-motion";

const LoadingAnimation = () => {
  const MotionImage = motion(Image);
  return (
    <Flex
      w="100%"
      h="100vh"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      gap="5"
    >
      <MotionImage
        src="PoolGuardImage.png"
        w="auto"
        h="300px"
        alt="product"
        justifyContent="center"
        animate={{
          scale: [1, 1.05, 1], // Scale up to 1.1 and back to 1
        }}
        transition={{
          duration: 1.8, // Time for one full pulse
          repeat: Infinity, // Loop the pulse effect
          repeatType: "loop", // Continuously loop the animation
        }}
      />
      <Text>Loading...</Text>
    </Flex>
  );
};

export default LoadingAnimation;
