import React, { useEffect, useState } from "react";
import { Flex, Text, Card, Image, Box, Icon } from "@chakra-ui/react";
import { supabase } from "../utils/supabaseClient";
import {
  MdBatteryFull,
  MdBattery90,
  MdBattery80,
  MdBattery60,
  MdBattery50,
  MdBattery30,
  MdBattery20,
  MdBatteryAlert,
  MdWifi,
  MdWifi2Bar,
  MdWifi1Bar,
} from "react-icons/md";

const DeviceData = () => {
  const [device, setDevice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchDeviceData = async () => {
    try {
      const {
        data: { session },
        error: sessionError,
      } = await supabase.auth.getSession();

      if (sessionError || !session) {
        setError("Error retrieving session data.");
        console.error("Session error:", sessionError);
        setLoading(false);
        return;
      }

      const { data: profile, error: profileError } = await supabase
        .from("profiles")
        .select("id, device_id")
        .eq("id", session.user.id)
        .single();

      if (profileError || !profile) {
        setError("Error fetching profile data.");
        console.error("Profile error:", profileError);
        setLoading(false);
        return;
      }

      const deviceId = profile.device_id;
      console.log("Fetched device ID:", deviceId);

      if (!deviceId) {
        setError("No device associated with this profile.");
        setLoading(false);
        return;
      }

      const { data: deviceData, error: deviceError } = await supabase
        .from("devices")
        .select("device_id, specifications, signal_strength, battery_level")
        .eq("device_id", deviceId)
        .single();

      if (deviceError) {
        console.error("Device lookup error:", deviceError.message);
        setError("Device not found or invalid.");
        return;
      }

      const [name, feature1, feature2, feature3] =
        deviceData.specifications.split(", ");
      deviceData.specifications = {
        name: name || "N/A",
        feature1: feature1 || "N/A",
        feature2: feature2 || "N/A",
        feature3: feature3 || "N/A",
      };
      setDevice(deviceData);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  // Helper functions to get the correct icon based on battery level and Wi-Fi strength
  const batteryLevelIcon = (batteryLevel) => {
    if (batteryLevel >= 90) return MdBatteryFull;
    if (batteryLevel >= 80) return MdBattery90;
    if (batteryLevel >= 60) return MdBattery80;
    if (batteryLevel >= 50) return MdBattery60;
    if (batteryLevel >= 30) return MdBattery50;
    if (batteryLevel >= 20) return MdBattery30;
    if (batteryLevel >= 10) return MdBattery20;
    return MdBatteryAlert; // If battery level is 0 or undefined
  };

  const wifiSignalIcon = (signalStrength) => {
    if (signalStrength === "strong") return MdWifi;
    if (signalStrength === "good") return MdWifi2Bar;
    return MdWifi1Bar; // For weak or undefined signal
  };

  useEffect(() => {
    fetchDeviceData();
  }, []);

  if (loading) {
    return <Text>Loading...</Text>;
  }

  if (error) {
    return (
      <Flex justifyContent="center" alignItems="center" h="full">
        <Text color="red.500">{error}</Text>
      </Flex>
    );
  }

  return (
    <Flex flexDir="column" alignItems="center" justifyContent="center" gap="3">
      <Card
        boxShadow="md"
        p="8"
        borderRadius="md"
        w="full"
        justifyContent="center"
        alignItems="center"
        gap="3"
      >
        <Image
          src="PoolGuardImage.png"
          w="fit-content"
          h="200px"
          alt="product"
          justifyContent="center"
        />
        <Flex flexDir="column" gap="2">
          <Text
            textAlign="center"
            fontSize="xl"
            fontWeight="bold"
            color="white"
          >
            {device.specifications.name}
          </Text>
          <Flex alignItems="center" justifyContent="center" gap="1">
            <Text
              textAlign="center"
              fontWeight="semibold"
              fontSize="sm"
              color="white"
            >
              {device.specifications.feature1}
            </Text>
            <Box w="0.5" h="4" bg="white" borderRadius="full" />
            <Text
              textAlign="center"
              fontSize="sm"
              color="white"
              fontWeight="semibold"
            >
              {device.specifications.feature2}
            </Text>
            <Box w="0.5" h="4" bg="white" borderRadius="full" />
            <Text
              textAlign="center"
              fontSize="sm"
              color="white"
              fontWeight="semibold"
            >
              {device.specifications.feature3}
            </Text>
          </Flex>
        </Flex>
      </Card>
      <Flex flexDir="row" w="full" h="14vh" gap="3">
        <Card w="full" h="full" bg="gray.700" borderRadius="md" boxShadow="md">
          <Flex
            justifyContent="center"
            alignItems="center"
            flexDir="column"
            w="full"
            h="full"
          >
            <Icon
              as={batteryLevelIcon(device.battery_level)}
              boxSize="8"
              color="green.400"
            />
            <Text fontSize="lg" fontWeight="medium" color="white">
              Battery: {device.battery_level}%
            </Text>
          </Flex>
        </Card>
        <Card w="full" h="full" bg="gray.700" borderRadius="md" boxShadow="md">
          <Flex
            justifyContent="center"
            alignItems="center"
            w="full"
            h="full"
            flexDir="column"
          >
            <Icon
              as={wifiSignalIcon(device.signal_strength)}
              boxSize="8"
              color="blue.400"
            />
            <Text fontSize="lg" fontWeight="medium" color="white">
              Signal: {device.signal_strength}
            </Text>
          </Flex>
        </Card>
      </Flex>
    </Flex>
  );
};

export default DeviceData;
